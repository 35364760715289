import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';

@Injectable({
  providedIn: 'root'
})
export class FinanceStatementService {

  constructor(private messageService: GlobalMessageService,
    public translate: TranslateService,) { }
  
  updateField(data: any[], fieldId: string, updates: any) {
    const index = data.findIndex(item => item.id === fieldId);
    if (index !== -1) {
      const updatedItem = { ...data[index], ...updates };
      data[index] = updatedItem;
      return true;
    }
  }


  validateForm(formElements: any[]) {
    const shownErrorMessages = new Set<string>();
    formElements.forEach(formElement => {
      if (formElement.form) {
      
        Object.keys(formElement.form.controls).forEach(controlName => {
          const control = formElement.form.controls[controlName];
          const controlLabel = formElement.data.find(item => item.name === controlName)?.label || controlName;
          const translatedLabel = this.translate.instant(controlLabel);

          control.markAsTouched();

          if (control.invalid && control.touched) {
            const errorMessage = this.getErrorMessage(control, translatedLabel);
            if (!shownErrorMessages.has(errorMessage)) {
              this.showErrorMessage(errorMessage);
              shownErrorMessages.add(errorMessage);
            }
          }
        });
      }
    });
  }


  getErrorMessage(control: AbstractControl, label: string): string {
    if (control.hasError('required')) {
      return `${label} is required.`;
    }
    if (control.hasError('minlength')) {
      return `${label} must be at least ${control.errors['minlength'].requiredLength} characters long.`;
    }
    if (control.hasError('maxlength')) {
      return `${label} cannot exceed ${control.errors['maxlength'].requiredLength} characters.`;
    }
    if (control.hasError('pattern')) {
      return `${label} has an invalid format.`;
    }
    return `${label} is invalid.`;
  }

  showErrorMessage(errorMessage: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Validation Error',
      detail: errorMessage,
      sticky: true
    });
  }


  setDefaultValue(formData, key, defaultValue = true) {
    const value = formData[key];
    return (value === null || value === undefined || value === "" || isNaN(value)) ? defaultValue : value;
  }
  
}
